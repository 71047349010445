<template>
    <basic-page class="qualita" :pagedata="this.pageData" bannerimg="/img/qualita-banner.jpg">
         <div class="container-beige intro container">
            <h3 class="occhiello occhiello-beige">
                {{ lang=='it' ? 'I migliori Processi, i migliori Ingredienti' : 'The best Processes, the best Ingredients'}}
            </h3>
            <h2>
                {{ lang=='it' ? 'Come riconoscere l\'Eccellenza' : 'How to recognize the Excellence'}}
            </h2>
        </div>
        <div class="container" v-if="this.pageData && this.pageData.field_page_ui_testo">
            <p class="occhiello-section">{{ lang=='it' ? 'Qualità dirompente' : 'Disrupting quality'}}</p>
                <div class=" text-container two-col section section-1">
                    <div class="images">
                        <div class="img img-2" v-parallax="-0.4"><img src="../assets/qd-img-2.png" alt=""></div>
                        <div class="img img-3" v-parallax="-0.2"><img src="../assets/qd-img-3.jpg" alt=""></div>
                        <div class="img img-4" v-parallax="-0.6"><img src="../assets/qd-img-4.jpg" alt=""></div>
                    </div>
                     <div class="text">
                        <h3 class="num" >01</h3>
                        <h2 class="section-title">{{ this.pageData.field_page_ui_testo[0].attributes.title }}</h2>
                        <div v-for="(t, index) in this.pageData.field_page_ui_testo[0].attributes.field_ui_testo" :key="'s1_uitext_'+index">
                            <p v-html="t.processed"></p>
                        </div>
                    </div>
                </div>
                
                <div class=" text-container two-col section section-2">
                    <div class="images">
                        <div class="img img-6" v-parallax="-0.2"><img src="../assets/qd-img-6.jpg" alt=""></div>
                        <div class="img img-7" v-parallax="-0.6"><img src="../assets/qd-img-7.png" alt=""></div>
                    </div>
                     <div class="text">
                        <h3 class="num num02">02</h3>
                            <h2 class="section-title">{{ this.pageData.field_page_ui_testo[1].attributes.title }}</h2>
                            <div v-for="(t, index) in this.pageData.field_page_ui_testo[1].attributes.field_ui_testo" :key="'s2_uitext_'+index">
                            <p v-html="t.processed"></p>
                        </div>
                        <div class="img img-8" v-parallax="-0.2"><img src="../assets/qd-img-8.jpg"  width="573" height="536" alt=""></div>
                        <div class="img img-9" v-parallax="-0.4"><img src="../assets/qd-img-9.png" alt=""></div>
                    </div>
                </div>

                <div class="  text-container section section-3">
                     <div class="text">
                        <h3 class="num num03">03</h3>
                            <h2 class="section-title">{{ this.pageData.field_page_ui_testo[2].attributes.title }}</h2>
                            <div v-for="(t, index) in this.pageData.field_page_ui_testo[2].attributes.field_ui_testo" :key="'s3_uitext_'+index">
                            <p v-html="t.processed"></p>
                        </div>
                    </div>
                    <div class="images">
                        <div class="img img-10"><img src="../assets/agrimontana-lavorazione-marroni.jpg" width="949" height="618" alt=""></div>
                        <div class="img img-11" v-parallax="-0.6"><img src="../assets/prestat-lavorazioni.jpeg" width="400" alt=""></div>
                        <div class="img img-12" v-parallax="-0.3"><img src="../assets/bottaia.jpg" width="550" alt=""></div>
                        <div class="img img-13" v-parallax="-0.6"><img src="../assets/qd-img-13.png" alt=""></div>
                    </div>
                </div>

                <div class="text-container two-col section section-4">
                    <div class="images">
                        <div class="img img-14" v-parallax="0.4"><img src="../assets/qd-img-14.png" alt=""></div>
                        <div class="img img-15"><img src="../assets/qd-img-15.jpg" alt=""></div>
                        <div class="img img-16" v-parallax="-0.6"><img src="../assets/qd-img-16.jpg" alt=""></div>
                    </div>
                     <div class="text">
                        <h3 class="num num04">04</h3>
                            <h2 class="section-title">{{ this.pageData.field_page_ui_testo[3].attributes.title }}</h2>
                            <div v-for="(t, index) in this.pageData.field_page_ui_testo[3].attributes.field_ui_testo" :key="'s4_uitext_'+index">
                            <p v-html="t.processed"></p>
                        </div>
                    </div>
                </div>
        </div>
    </basic-page>
</template>

<script>
import { fetchSinglePage, getTranslation } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'
export default {

    components: { BasicPage },
    name: 'qualita-dirompente',
    data: () => {
        return {
            currentPageId: "1f227405-542e-4aa6-acfe-eb2efd90a281",
            pageData: { attributes: {} },
            p1: 0,
            p2: 0,
            mouseX: 0,
            mouseY: 0
        }
    },
    methods: {
        
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        }
    },
    mounted() {
        fetchSinglePage(this.currentPageId, this.lang)
        .then(res => {
            this.pageData = res[0]
        }) 
    },
    watch: {
        lang() {
            getTranslation(this.$route.name, this.lang)
            .then(res => {
                this.$router.push({path: res})
            })

            fetchSinglePage(this.currentPageId, this.lang)
            .then(res => {
                this.pageData = res[0]
            }) 
        }
    }
    
}
</script>

<style lang="scss" scoped>
@import "../styles/colors";
    .hide{
        display: none !important;
    }
</style>
